import React from "react";

const ContentParagraph = ({
    color,
    style,
    children
}) => (
    <p
        className={`size-20 ${color ? color : 'is-grey-1'}`}
        style={{
            fontFamily: 'Gilroy-Regular',
            maxWidth: '52.688rem',
            ...style,
        }}
    >
        {children}
    </p>
);

export const PlanActivationContentParagraph = ({ children }) => (
    <ContentParagraph
        color="is-black"
        style={{
            maxWidth: 'unset',
            padding: '10px 20px',
            background: '#FFF6F0',
            border: '1px solid #FF7923',
            borderRadius: '15px',
        }}
    >
        {children}
    </ContentParagraph>
);

export default ContentParagraph;