import React from "react";
import { Link } from "gatsby";

const ContentLink = ({
    href,
    target,
    color,
    margin,
    width,
    smallText,
    children,
}) => (
    <Link
        className={`frends-button rounded is-outlined-circle ${color ? color : 'is-purple'}`}
        style={{ margin: margin || '0', width: width || 'fit-content', textAlign: 'center' }}
        target={target || undefined}
        to={href}
    >
        <span
            className={`${smallText && 'size-16'}`}
            style={{ fontWeight: '600' }}
        >
            {children}
        </span>
    </Link>
);

export const ContentLinkExternal = ({
    href,
    target,
    color,
    margin,
    width,
    smallText,
    children,
}) => (
    <a
        className={`frends-button rounded is-outlined-circle ${color ? color : 'is-purple'}`}
        style={{ margin: margin || '0', width: width || 'fit-content', textAlign: 'center' }}
        target={target || '_blank'}
        href={href}
    >
        <span
            className={`${smallText && 'size-16'}`}
            style={{ fontWeight: '600' }}
        >
            {children}
        </span>
    </a>
);

export default ContentLink;