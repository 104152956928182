import React from "react";

const DesktopMenuAndContentContainer = ({
    menu,
    content,
}) => {
    return (
        <section
            className="section white-bg"
            style={{
                marginTop: '5rem',
                padding: '3.938rem 4.875rem',
                minHeight: '100vh',
            }}
        >
            <h2 style={{ fontSize: '5.313rem' }}>
                <span className="font-variable is-purple">
                    My
                </span>
                &nbsp;
                <span className="is-transparent text-stroke-purple">
                    Account
                </span>
            </h2>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'max-content auto',
                    marginTop: '3.313rem',
                    gap: '3.375rem',
                }}
            >
                <aside
                    className="menu platform-menu"
                    style={{ marginTop: '0.625rem' }}
                >
                    {menu}
                </aside>

                <div
                    style={{
                        borderLeft: '1px solid #B3CBCB',
                        paddingLeft: '5.5rem',
                    }}
                >
                    {content}
                </div>
            </div>
        </section>
    );
};

export default DesktopMenuAndContentContainer;