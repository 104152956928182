import React from "react";
import { PageContentLoadingIndicator } from "./ContentLoadingIndicator";

const ContentContainer = ({ children, loading }) => (
    <>
        {loading ? (
            <PageContentLoadingIndicator />
        ) : (
            <div
                className="is-flex is-flex-direction-column is-align-items-start"
                style={{ gap: '1.688rem', minHeight: '25vh' }}
            >
                {children}
            </div>
        )}
    </>
);

export default ContentContainer;