import React from "react";

const MobileContentContainer = ({ children }) => (
    <section
        className="section white-bg"
        style={{
            paddingTop: '6.25rem',
            paddingBottom: '6.25rem',
            minHeight: '100vh'
        }}
    >
        <div className="container">
            {children}
        </div>
    </section>
);

export default MobileContentContainer;