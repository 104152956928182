import React from "react";
import { Link } from "gatsby";

const Menu = ({ items }) => (
    <ul className="menu-list">
        {items.map((item, idx) => (
            <li
                key={idx}
                className="top-level-item is-black"
            >
                <Link
                    to={item.path}
                    className={`top-level-title-container${item.open ? ' is-open' : ''}`}
                >
                    <span className="top-level-title">
                        {item.text}
                    </span>
                </Link>
            </li>
        ))}

        <li className="top-level-item">
            <a
                href="/services/frends-academy"
                className="top-level-title-container"
                target="_blank"
            >
                <span className="is-purple is-purple-border-bottom read-more size-16">
                    &nbsp;&nbsp;Frends academy&nbsp;&nbsp;
                </span>
            </a>
        </li>

        <li className="top-level-item mt-4">
            <a
                href="/demo"
                className="top-level-title-container"
                target="_blank"
            >
                <span className="is-purple is-purple-border-bottom read-more size-16">
                    &nbsp;&nbsp;Schedule a demo&nbsp;&nbsp;
                </span>
            </a>
        </li>
    </ul>
);

export default Menu;