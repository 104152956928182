import React from "react";

import { useAuthClaimCheck, TENANT_PATH } from "../../components/profile/shared/AuthClaimCheckHook";
import PageContentContainer from "../../components/profile/shared/PageContentContainer";
import SuccessContentStripe from "../../components/profile/shared/PurchaseSuccessContent";

import { pushSAEvent } from "../../helpers";

export default ({ location }) => {
    const loading = useAuthClaimCheck();

    const menuItems = [
        {
            path: TENANT_PATH,
            text: 'My Frends',
            open: true,
        },
    ];

    pushSAEvent('visit_profile_purchase_success');

    return (
        <PageContentContainer
            title="Purchase successful"
            location={location}
            loading={loading}
            menuItems={menuItems}
            content={<SuccessContentStripe tenantLinkPath={TENANT_PATH} />}
        />
    );
};