import React from "react";
import { Link } from "gatsby";

import ContentContainer from "./ContentContainer";
import ContentParagraph from "./ContentParagraph";
import ContentTitle from "./ContentTitle";
import ContentSubTitle from "./ContentSubTitle";
import ContentLink from "./ContentLink";

const SuccessContentStripe = ({
    tenantLinkPath
}) => (
    <ContentContainer>
        <ContentParagraph>
            <Link
                to={tenantLinkPath}
                className="is-purple is-underlined"
            >
                Back to tenant view
            </Link>
        </ContentParagraph>
        <ContentTitle>
            Payment for your subcription was successful!
        </ContentTitle>
        <ContentSubTitle>
            You'll now have an access to your tenant view, where you can manage your subscription and see various statistics.
        </ContentSubTitle>
        <ContentSubTitle>
            Your Frends tenant is currently being updated. This will take few minutes, after which you can continue using Frends with your selected subscription.
        </ContentSubTitle>
        <ContentLink
            href={tenantLinkPath}
            target="_self"
        >
            Back to tenant view
        </ContentLink>
    </ContentContainer>
);


export const SuccessContentEContract = ({
    tenantLinkPath
}) => (
    <ContentContainer>
        <ContentParagraph>
            <Link
                to={tenantLinkPath}
                className="is-purple is-underlined"
            >
                Back to tenant view
            </Link>
        </ContentParagraph>
        <ContentTitle>
            eContracting process started successfully!
        </ContentTitle>
        <ContentSubTitle>
            Check your email for a link to our eContracting service. In the eContracting service you'll need to provide billing and contact information for your company, and accept Frends Terms of Service. In the eContracting service you can also invite collagues to review the contract and you can also leave comments for the Frends sales team if there's anything unclear that you want to discuss with us.
        </ContentSubTitle>
        <ContentSubTitle>
            If you didn't receive an email from us, please contact <a href="mailto:support@frends.com">support@frends.com.</a>
        </ContentSubTitle>
        <ContentLink
            href={tenantLinkPath}
            target="_self"
        >
            Back to tenant view
        </ContentLink>
    </ContentContainer>
);

export default SuccessContentStripe;