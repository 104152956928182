import React from "react";

import Layout from "../../layout/Layout";

import ContentLoadingIndicatorContainer from "./ContentLoadingIndicatorContainer";
import MobileContentContainer from "./MobileContentContainer";
import MobileMenuContainer from "./MobileMenuContainer";
import DesktopMenuAndContentContainer from "./DesktopMenuAndContentContainer";
import Menu from "./Menu";

const PageContentContainer = ({
    title,
    location,
    content,
    menuItems,
    loading
}) => {
    const isTablet = () => (typeof window !== 'undefined' && window.innerWidth <= 1361);
    const [mobileView, setMobileView] = React.useState(isTablet());

    React.useEffect(() => {
        const handleWindowResize = () => {
            setMobileView(isTablet());
        };

        handleWindowResize();

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return (
        <Layout
            title={title}
            transparentNavigation={false}
            location={location}
        >
            {loading ? (
                <ContentLoadingIndicatorContainer />
            ) : (
                <main
                    id="profile-page"
                    style={{ marginTop: "-80px" }}
                >
                    {mobileView ? (
                        <>
                            <MobileContentContainer>
                                {content}
                            </MobileContentContainer>
                            <MobileMenuContainer>
                                <Menu items={menuItems} />
                            </MobileMenuContainer>
                        </>
                    ) : (
                        <DesktopMenuAndContentContainer
                            menu={<Menu items={menuItems} />}
                            content={content}
                        />
                    )}
                </main>
            )}
        </Layout>
    );
};

export default PageContentContainer;