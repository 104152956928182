import React from "react";
import { useSpring, animated } from 'react-spring';

import small_transcript_orange from "../../../assets/images/small_transcript_orange.svg";
import orange_chevron from "../../../assets/images/orange_chevron.svg";

const MobileMenuContainer = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const spring = useSpring({
        opacity: open ? 1 : 0,
        transform: open ? "translateY(0px)" : "translateY(1000px)",
        padding: '10px',
        overflow: 'hidden',
    });

    return (
        <div className="mobile-platform-catalogue-container">
            <animated.div
                style={spring}
                className="platform-menu"
            >
                {children}
            </animated.div>

            <nav
                className="navbar mobile-platform-catalogue"
                onClick={() => setOpen(curState => !curState)}
                aria-label="your view catalogue"
            >
                <div className="navbar-item">
                    <img
                        src={small_transcript_orange}
                        style={{ verticalAlign: "middle" }}
                        alt=""
                    />
                </div>
                <div className="navbar-item">
                    <p className="is-orange size-24">
                        your view
                    </p>
                </div>
                <div className="navbar-item chevron">
                    <img
                        src={orange_chevron}
                        style={{
                            verticalAlign: "middle",
                            transform: open ? "rotate(0deg)" : "rotate(180deg)",
                            transition: "all .5s ease"
                        }}
                        alt=""
                    />
                </div>
            </nav>
        </div>
    );
};

export default MobileMenuContainer;